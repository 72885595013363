<template lang='pug'>
	.h-100.add-requisite
		.d-flex.flex-column.h-100(v-if="step == 1")
			p.b3.mb-2 {{ $t('finance.addRequisiteDesc1') }}
			p.b3.mb-2 {{ $t('finance.addRequisiteDesc2') }}
			p.b3.text-grey.mb-3(v-html="$t('finance.addRequisiteDesc3')")
			my-input.mb-4(
				v-model="form.addressTRC"
				:label="$t('finance.addressTRC')"
				:icon="'icon-document'"
				:errorFeedback="error"
			)
			.btn.btn-primary.mt-auto(@click='check' :class="{'disabled': isPending || form.addressTRC == '' || form.addressTRC == null || isloading}")
				.loader(v-if="isloading")
				span(v-else) {{$t('wizard.continue')}}

		.d-flex.flex-column.h-100(v-if="step == 2")
			p.b3.mb-3 {{ $t('finance.addRequisiteDesc4') }}
			span.b1.text-center.border-rounded.mb-3.add-requisite__address {{ form.addressTRC }}
			b-form-checkbox.b3.mb-4(id="confirm-address" v-model="form.isConfirmAddress" name="confirm-address" :value="true" :unchecked-value="false") {{ $t('finance.addRequisiteDesc5') }}
			.d-flex.mt-auto
				.btn.btn-border.w-25.mr-3(@click='step = 1') {{$t('wizard.prev')}}
				.btn.btn-primary.w-75(@click='save' :class="{'disabled': isPending || form.isConfirmAddress == false || isloading}")
					.loader(v-if="isloading")
					span(v-else) {{$t('wizard.continue')}}
</template>

<script>
import MyInput from "@/components/UI/MyInput";
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'AddRequisite',
	components: {
		MyInput
	},
	data: () => ({
		usdtAddress: null,
		error: '',
		step: 1,
		form: {
			addressTRC: null,
			isConfirmAddress: false
		},
		isloading: false
	}),
	computed: {
		...mapGetters({
			isPending	: 'auth/isPending',
			item		: 'modals/usdtRequisites'
		}),
		isValidAddress() {
			return /^(T)[a-zA-Z0-9]{33}$/.test(this.form.addressTRC)
		},
	},
	mounted() {
		document.querySelector('.add-requisite input').addEventListener('keydown', e => {
			if(e.code == 'Space') {
				e.preventDefault();
			}
		})
	},
	methods: {
		...mapActions({
			edit		: 'finance/editAccount',
			checkUsdt	: 'finance/checkUsdt'
		}),
		setDefault () {
			this.form.addressTRC = null;
			this.form.isConfirmAddress = false;
			this.step = 1;
		},
		check() {
			if ( this.form.addressTRC == '' ) return this.error = $t('promotions.form.error');
			if ( !this.isValidAddress ) return this.error = this.$t('errors.usdtAddress');
			this.isloading = true;

			this.checkUsdt(this.form.addressTRC)
				.then( v => {
					this.error = '';
					this.step++;
					this.isloading = false;
				})
				.catch( v => {
					this.error = this.$t('errors.usdtAddress');
					this.isloading = false;
				})
		},
		save() {
			this.isloading = true;
			this.edit({id: this.item.id, usdtAddress: this.form.addressTRC})
				.then( v => {
					this.item.setUsdtAddress(this.form.addressTRC);
					this.$notify('success', null, this.$t('success.requisitesAdded'));
					this.$emit('openWithdrawCrypto');
					this.isloading = false;
					this.$notify('success', this.$t('forms.doneS'));
				})
				.catch( v => {
					this.$notify('error', v);
					this.isloading = false;
				})
		},
	},
	watch : {
		item : {
			handler (nV, oV) {
				if ( nV != null ) {
					this.setDefault();
					return this.$refs.modal.show();
				}

				if ( this.$refs.modal )
					this.close();
			}
		},
		'form.addressTRC'(newVal) {
			this.form.addressTRC = newVal.replace(' ', '');
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.add-requisite {
	width: 478px;
	height: 100%;

	@include respond-below(sm) {
		width: 100%;
	}

	&__address {
		padding: 20px;
		background-color: var(--brand-bg-primary);

		@include respond-below(sm) {
			padding: 20px 2px;
		}
	}
}
</style>
