<template lang='pug'>
	.finance
		div(v-if='me && isReady')
			.d-flex.align-items-center.mb-4
				h2.mr-3 {{ $t('h1.finance') }}

			b-tabs(nav-class='tabs-filled tabs-filled-transparent' v-model='tab' no-fade)
				b-tab(:title="$t('finance.accounts2')")
					.d-flex.justify-content-between.flex-wrap(v-if='accountListFiltered && accountListFiltered.length > 0')
						card-bank-account(v-for='i in accountListFiltered' :key='i.id' :item='i' @openModalAddCard='openModalAddCard(i)' @openModalRemove='openModalRemove(i)' @openModalRemoveAccount='openModalRemoveAccount(i)' @openWithdrawCreated="openWithdrawCreated")

				b-tab(:title="$t('finance.income')" lazy)
					.finance__wrap(v-show="hasIncomeItems")
						.d-flex.align-items-center.mb-3
							my-input-search.mr-2.finance__search(
								v-model.trim="filters[1].find.value"
								:placeholder="$t('dashboards.search')"
							)

							pw-btn-icon.mr-2(:used="usedIncome" :icon="'fontello-icon-setting-4'"  @click="$bvModal.show('filters-income')")
							//- pw-btn-icon(@click="exportPayments" :disabled="total1 == 0" :icon="'fontello-icon-export'")
							table-per-page.ml-auto(v-if="!isMobile && total1" @onChange="selectPageSize" :from="from1" :total="total1")

						table-income.vuetable(
							:perPage='filters[1].itemsPerPage.value'
							:filters='filters[1]'
							:hideSearch="true"
							:hidePerPage="true"
							@onChangeCurrentPage='changeCurrentPage'
							@onGetItems='onLoad'
						)

					.d-flex.flex-column.mx-auto.py-5.mt-3(v-if='!hasIncomeItems')
						h1.mb-3.text-center 😢
						span.b1.text-center {{ $t('errors.emptyIncome') }}

					modal-general(:id="'filters-income'" :contentClass="'content-filters'" :showCloseIcon="false" :title="$t('button.filters')")
						finance-filters(@onApply="applyFinance" :projectsList="projectList" @click="$bvModal.hide('filters-income')" type='income')

				b-tab(:title="$t('finance.payments')")
					.finance__wrap(v-show="hasWithdrawalItems")
						.d-flex.align-items-center.mb-3
							my-input-search.mr-2.finance__search(
								v-model.trim="filters[2].find.value"
								:placeholder="$t('dashboards.search')"
							)
							pw-btn-icon.mr-2(:used="usedWithdrawals" :icon="'fontello-icon-setting-4'"  @click="$bvModal.show('filters-withdrawals')")
							//- pw-btn-icon(@click="exportWithdrawals" :disabled="total2 == 0" :icon="'fontello-icon-export'")
							table-per-page.ml-auto(v-if="!isMobile && total2" @onChange="selectPageSize" :from="from2" :total="total2")

						table-withdrawal.vuetable(
							:perPage='filters[2].itemsPerPage.value'
							:filters='filters[2]'
							:hideSearch="true"
							:hidePerPage="true"
							@onChangeCurrentPage='changeCurrentPageWithdrawal'
							@onGetItems='onLoad'
						)

					.d-flex.flex-column.mx-auto.py-5.mt-3(v-if='!hasWithdrawalItems')
						h1.mb-3.text-center 😢
						span.b1.text-center {{ $t('errors.emptyPayments') }}

					modal-general(:id="'filters-withdrawals'" :contentClass="'content-filters'" :showCloseIcon="false" :title="$t('button.filters')")
						finance-filters(@onApply="applyWithdrawals" @click="$bvModal.hide('filters-withdrawals')" type='withdrawals')
		.loading(v-else)

		modal-withdraw(@openWithdrawCreated="openWithdrawCreated")
		modal-withdraw-schedule
		modal-add-bank-card(:item='selectedCardForAddCard' @waitingCard="openModalWaitingCard" @errorCard="openModalCardError")
		modal-error-add-bank-card(:reason="reason" :code="code")
		modal-remove-bank-card(:item='selectedCardForRemove')
		modal-remove-account(:account='selectedAccountForRemove')
		modal-edit-account-company
		modal-inactive-shop
		modal-waiting-card()
		modal-withdrawal-success
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import CardBankAccount from '@/components/Cards/BankAccount'
import CardBankCard from '@/components/Cards/BankCard'
import CardPersonal from '@/components/Cards/Personal'
import HowItWorksAlert from '@/components/Cards/HowItWorksAlert'
import Withdraw from '@/components/Modals/Withdraw'
import WithdrawSchedule from '@/components/Modals/WithdrawSchedule'
import AddBankCard from '@/components/Modals/AddBankCard'
import ErrorAddBankCard from '@/components/Modals/ErrorAddBankCard'
import RemoveBankCard from '@/components/Modals/RemoveBankCard'
import RemoveAccount from '@/components/Modals/RemoveAccount'
import ModalEditAccountCompany from '@/components/Modals/EditAccount'
import ModalInActiveShop from '@/components/Modals/ModalInActiveShop'
import ModalWaitingCard from '@/components/Modals/ModalWaitingCard'
import ModalWithdrawalSuccess from '@/components/Modals/ModalWithdrawalSuccess'
import PwDropdownAccounts from '@/components/Form/PwDropdownAccounts'
import PwDropdownProjects from '@/components/Form/PwDropdownProjects'
import TablePerPage from '@/components/Tables/TablePerPage'
import PwBtnReset from '@/components/Buttons/PwBtnReset'
import PwBtnMobileFilters from '@/components/Buttons/PwBtnMobileFilters'
import PwSelect2 from '@/components/Form/PwSelect2'
import AddPersonal from '@/components/Form/AddPersonal'

import FinanceIncomePartnerTable from '@/components/Tables/FinanceIncomePartner'
import FinanceWithdrawPartnerTable from '@/components/Tables/FinanceWithdrawPartner'

import MyInputSearch from "@/components/UI/MyInputSearch";
import PwBtnIcon from '@/components/Buttons/PwBtnIcon';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import FinanceFilters from '@/components/Form/FinanceFilters';

import moment from 'moment-timezone';
import { tablePerPage, tablePageSizesExtended } from '@/constants/config';
import { localePicker, localePickerRanges } from '@/constants/config';
import {STATUS as STATUS_WITHDRAWALS} from '@/models/withdraw'
import { STORAGE } from '@/api/storage';
import { bus } from '@/main.js';

export default {
	components: {
		CardBankAccount,
		CardBankCard,
		CardPersonal,
		'modal-withdraw' 			: Withdraw,
		'modal-add-bank-card'		: AddBankCard,
		'modal-error-add-bank-card'	: ErrorAddBankCard,
		'modal-remove-bank-card'	: RemoveBankCard,
		'modal-remove-account'		: RemoveAccount,
		'modal-withdraw-schedule'	: WithdrawSchedule,
		'modal-inactive-shop'		: ModalInActiveShop,
		'form-add-personal'	: AddPersonal,
		ModalEditAccountCompany,
		ModalWaitingCard,
		ModalWithdrawalSuccess,
		HowItWorksAlert,
		PwDropdownAccounts,
		PwDropdownProjects,
		PwBtnReset,
		PwBtnMobileFilters,
		PwSelect2,
		TablePerPage,

		MyInputSearch,
		PwBtnIcon,
		ModalGeneral,
		'table-income' 		: FinanceIncomePartnerTable,
		'table-withdrawal'	: FinanceWithdrawPartnerTable,
		FinanceFilters
	},
	metaInfo () { return {title: bus.$t('h1.finance')}},
	data: () => ({
		usedIncome: false,
		usedWithdrawals: false,
		notJustOpenedPage: true,
		filters: [{
		}, {
			project		: {value: null},
			account		: {value: null},
			status		: {value: null},
			find		: {value: null},
			'createdAt[after]'	: {value: null},
			'createdAt[before]'	: {value: null},
			tz 		: {value: moment.tz.guess()},
			itemsPerPage		: {value: tablePerPage},
			page 	    		: {value: 1},
		}, {
			account		: {value: null},
			status		: {value: null},
			find		: {value: null},
			'createdAt[after]'	: {value: null},
			'createdAt[before]'	: {value: null},
			tz 		: {value: moment.tz.guess()},
			itemsPerPage     	: {value: tablePerPage},
			page 	    		: {value: 1},
		}],
		date 		: {
			startDate 	: null,
			endDate		: new Date(),
			period 		: null
		},
		date1 		: {
			startDate 	: null,
			endDate		: new Date(),
			period 		: null
		},
		defaultDate 		: {
			startDate 	: null,
			endDate		: null,
			period 		: null
		},
		defaultDate1 		: {
			startDate 	: null,
			endDate		: null,
			period 		: null
		},
		pageSizes	: tablePageSizesExtended,
		selectedAccountForRemove	: null,
		selectedCardForRemove		: null,
		selectedCardForAddCard		: null,
		currentPage 	    		: {value: 1},
		currentPageWithdrawal  		: {value: 1},
		tab: 0,
		errors: null,
		account: null,
		isReady: false,
		forAll: false,
		locale: ((STORAGE.getLang() && localePicker.filter(x => x.id === STORAGE.getLang()).length > 0) ? localePicker.find(x => x.id === STORAGE.getLang()) : localePicker[0]),
		ranges: ((STORAGE.getLang() && localePicker.findIndex(x => x.id === STORAGE.getLang()) != -1 ) ? localePickerRanges[localePicker.findIndex(x => x.id === STORAGE.getLang())] : localePickerRanges[0]),
		from1: 0,
		from2: 0,
		reason: '',
		code: ''
	}),
	computed: {
		...mapGetters({
			withdrawals : 'finance/withdrawals',
			total1	    : 'finance/incomeTotal',
			total2	    : 'finance/withdrawalsTotal',
			activeCards : 'finance/activeCards',
			accountList	: 'finance/accounts',
			projectList	: 'project/itemsShort',

			isPendingIncome 	: 'finance/isPendingIncome',
			isPendingWithdrawal : 'finance/isPendingWithdrawal',
		}),
		accountListFiltered () {
			return _.sortBy(this.accountList, a => {
				return a.isRUB() ? 0 : 1;
			})
		},
		hasIncomeItems() {
			if ( this.isPendingIncome || this.notJustOpenedPage ) return true;

			if ( !this.hasFiltersIncome() && this.total1 == 0 ) return false;

			return true;
		},
		hasWithdrawalItems() {
			if ( this.isPendingWithdrawal || this.notJustOpenedPage ) return true;

			if ( !this.hasFiltersWithdraw() && this.total2 == 0 ) return false;

			return true;
		},
		to1 () {
			let max = this.currentPage.value * this.filters[1].itemsPerPage.value;
			return max < this.total1 ? max : this.total1;
		},
		to2 () {
			let max = this.currentPageWithdrawal.value * this.filters[2].itemsPerPage.value;
			return max < this.total2 ? max : this.total2;
		},
	},
	methods : {
		...mapActions({
			getAccounts 	: 'finance/accounts',
			getFinance		: 'finance/partnerFinance',
			getWithdrawals 	: 'finance/withdrawals',
			getProjects		: 'project/shortList',
			getActiveCards	: 'finance/activeCards',
		}),
		hasFiltersIncome() {
			if ( this.filters[1].find.value ||
				!(this.filters[1].account.value == null || this.filters[1].account.value.length == 0) ||
				!(this.filters[1].project.value == null || this.filters[1].project.value.length == 0) ||
				!(this.filters[1].status.value == null || this.filters[1].status.value.length == 0) ||
				this.filters[1]['createdAt[after]'].value != null ||
				this.filters[1]['createdAt[before]'].value != null) return true;
			return false;
		},
		hasFiltersWithdraw() {
			if ( this.filters[2].find.value ||
				!(this.filters[2].account.value == null || this.filters[2].account.value.length == 0) ||
				!(this.filters[2].status.value == null || this.filters[2].status.value.length == 0) ||
				this.filters[2]['createdAt[after]'].value != null ||
				this.filters[2]['createdAt[before]'].value != null) return true;
			return false;
		},
		exportPayments() {
			bus.$emit('tableFinanceIncomeExport');
		},
		exportWithdrawals() {
			bus.$emit('tableFinanceExport');
		},
		applyFinance(v) {
			this.filters[1].account	= {value: v.accountList};
			this.filters[1].project = {value: v.projects};
			this.filters[1].status	= {value: v.status};
			this.filters[1]['createdAt[after]']	= {value: v.incomeDate && v.incomeDate.start ? moment.utc(v.incomeDate.start).format('YYYY-MM-DDT00:00:00') : null};;
			this.filters[1]['createdAt[before]'] = {value: v.incomeDate && v.incomeDate.end ? moment.utc(v.incomeDate.end).format('YYYY-MM-DDT23:59:59') : null};
			this.filters[1].page = {value: 1};
			this.$nextTick( () => {
				this.usedIncome = v.used;
				bus.$emit('tableFinanceIncomeRefresh');
			})
		},
		applyWithdrawals(v) {
			this.filters[2].status	= {value: v.status};
			this.filters[2].account	= {value: v.accountList};
			this.filters[2]['createdAt[after]']	= {value: v.incomeDate && v.incomeDate.start ? moment.utc(v.incomeDate.start).format('YYYY-MM-DDT00:00:00') : null};;
			this.filters[2]['createdAt[before]'] = {value: v.incomeDate && v.incomeDate.end ? moment.utc(v.incomeDate.end).format('YYYY-MM-DDT23:59:59') : null};
			this.filters[2].page = {value: 1};
			this.$nextTick( () => {
				this.usedWithdrawals = v.used;
				bus.$emit('tableFinanceWithdrawalRefresh');
			})
		},
		onLoad(v) {
			this.$bvModal.hide(`filters-income`);
			this.$bvModal.hide(`filters-withdrawals`);
			this.notJustOpenedPage = false;
			if ( v.type == 'payments' ) return this.from1 = v.amount;
			this.from2 = v.amount;
		},
		openModalRemoveAccount ( item ) {
			this.selectedAccountForRemove = null;

			this.$nextTick( () => {
				this.selectedAccountForRemove = item;
			})
		},
		openModalAddCard ( item ) {
			this.selectedCardForAddCard = null;

			this.$nextTick( () => {
				this.selectedCardForAddCard = item;
			})
		},
		openWithdrawCreated() {
			this.$bvModal.show(`withdraw-created`);
		},
		openModalWaitingCard() {
			this.$bvModal.show('modalWaitingCard');
		},
		selectDate ( val ) {
			this.filters[this.tab]['createdAt[after]'] 	= {value: val.startDate == null ? null : moment.utc(val.startDate).format('YYYY-MM-DDT00:00:00')};
			this.filters[this.tab]['createdAt[before]']	= {value: val.endDate == null ? null : moment.utc(val.endDate).format('YYYY-MM-DDT23:59:59')};
		},
		clearFilters () {
			this.filters[this.tab].find				= {value: null};
			this.filters[this.tab]['createdAt[after]'] 	= {value: null};
			this.filters[this.tab]['createdAt[before]']	= {value: null};

			if ( this.tab == 1 ) {
				this.filters[this.tab].project			= {value: null};
				this.date = {
					startDate 	: null,
					endDate		: this.defaultDate.endDate,
				}
				return;
			}

			this.date1 = {
				startDate 	: null,
				endDate		: this.defaultDate1.endDate,
			}
		},
		selectAccount ( p ) {
			this.filters[this.tab].account = {value: p.id, title: p.id};
		},
		selectProject ( p ) {
			this.filters[this.tab].project = {value: p.id, title: p.title};
		},
		selectPageSize(perPage) {
			this.filters[this.tab].itemsPerPage.value = perPage;
		},
		openModal( name ) {
			this[name] = null;
			this.$nextTick( () => { this[name] = this.account });
		},
		openModalAddAccount () {
			this.$store.commit('finance/setWizardAddAccountOpened', true);
		},
		removeRequisites () {
			this.account.setRequisite(null);
		},
		changeCurrentPage (v) {
			this.currentPage.value = v;
		},
		changeCurrentPageWithdrawal (v) {
			this.currentPageWithdrawal.value = v;
		},
		openModalFilters ( index ) {
			this.$store.commit('project/setItemsShort', null);
			this.$store.commit('modals/setMobileFilters', null);

			let params = {
				date: {startDate: this.filters[this.tab]['createdAt[after]'].value, endDate: this.filters[this.tab]['createdAt[before]'].value}
			}

			if ( this.tab == 1 ) {
				params.projects = _.cloneDeep(this.filters[this.tab].project.value);
				params.defaultDate = _.cloneDeep(this.defaultDate);
			}

			if ( this.tab == 2 ) {
				params.defaultDate = _.cloneDeep(this.defaultDate1);
			}

			this.$nextTick( () => this.$store.commit('modals/setMobileFilters', params))
		},
		openModalCardError() {
			this.reason = STORAGE.getCardError();
			this.code = STORAGE.getCardErrorCode() ? STORAGE.getCardErrorCode() : '';
			this.$bvModal.hide('modalWaitingCard');
			this.$bvModal.show('modalerroraddbankcard');
		},
	},

	async created () {
		//clear mobdal filters
		this.$store.commit('modals/financeFilters', {type: 'income'});
		this.$store.commit('modals/financeFilters', {type: 'withdrawals'});

		await this.getAccounts().then( async v => {
			this.getProjects();
			await this.getFinance({id: _.map(this.accountList, a => a.id)}).then( list => {
			_.each(this.accountList, a => {
					_.each(list, l => {
						if ( a.id == l.id ) {
							a.setFinancePartner(l);
						}
					})
				})
			});

			this.isReady = true;
		});

		if ( STORAGE.hasCardError() ) {
			setTimeout( () => {
				this.$bvModal.hide('modalWaitingCard');
				this.$bvModal.show('modalerroraddbankcard')
			}, 100);
		}
	},
	mounted() {
		this.$root.$on('bv::dropdown::show', bvEvent => {
			bus.$emit('hideDateRangePicker')
		})
	},
	watch : {
		tab (nv, ov) {
			if ( nv != ov ) {
				if (nv == 1) {
					this.notJustOpenedPage = true;
					this.$nextTick( () => {bus.$emit('tableFinanceIncomeRefresh')});
				}
				if (nv == 2) {
					this.notJustOpenedPage = true;
					this.$nextTick( () => {bus.$emit('tableFinanceWithdrawalRefresh')});
				}
			}
		}
	}
}
</script>
<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.finance {
	height: 100%;

	&__wrap {
		padding: 20px;
		height: max-content;
		background-color: var(--grayscale-dark-white-back);
		border-radius: var(--border-radius-rounded);
		box-shadow: var(--box-shadow);

		@include respond-below(md) {
			padding: 16px;
			border-radius: 0;
			margin: 0 -12px;
			box-shadow: var(--box-shadow-mobile);
		}
	}

	&__search {
		max-width: 180px;

		@include respond-below(sm) {
			max-width: 100%;
		}
	}

	&__withdraw-img {
		display: block;
		width: 192px;
		height: 192px;
	}
}
</style>
