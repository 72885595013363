<template lang="pug">
	b-pagination.justify-content-center.m-0(
		v-if='totalItems'
		v-model='pageIndex'
		:total-rows='totalItems'
		:per-page='perPage'
		align="center"
		prev-text='Пред'
		next-text='След'
		:hide-goto-end-buttons='true'
		size='sm'
		class="table-pagination")

		template(v-slot:next-text='')
			i.simple-icon-arrow-right
		template(v-slot:prev-text='')
			i.simple-icon-arrow-left
		template(v-slot:first-text='')
			i.simple-icon-control-start
		template(v-slot:last-text='')
			i.simple-icon-control-end
</template>

<script>
	export default {
		name: 'DTableElPagination',
		props: {
			hideInfo: false,
			hidePagination: false,
			perPage: {
				type: Number,
				default: 10
			},
			currentPage: {
				type: Number,
				default: null
			},
			totalPages: {
				type: Number,
				default: 0
			},
			totalItems: {
				type: Number,
				default: 0
			}
		},
		computed: {
			pageIndex: {
				get() {
					return this.currentPage
				},
				set(page) {
					this.$emit("changepage", page);
				}
			}
		}
	}
</script>
