<template lang='pug'>
	b-modal#modalremovebankcard(ref='modal' centered content-class="modal-remove-bank-card" header-class="d-flex p-0 border-0 align-items-center mb-3" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
		template(#modal-header="{ close }")
			span.b1(v-if='item && !item.canRemove') {{ $t('finance.cardCantRemove') }}
			span.b1(v-else-if='item') {{ $t('forms.removeCard') }}
			icon-close.modal-remove-bank-card__close(@click="close()")

		p.b3.mb-3(v-if='item && !item.canRemove')
			| {{ $t('finance.cardCantRemoveDesc') }}
			a(:href='support' target='_blank') &nbsp;@{{$t('contacts.support_tg')}}

		p.b3.mb-3(v-else-if='item') {{ $t('forms.cardRemoved') }}

		.btn.btn-primary.w-100(v-if='item && !item.canRemove' @click="close") {{ $t('social.good') }}

		.d-flex.justify-content-between(v-else-if='item')
			.btn.btn-border.btn-small.w-100.mr-4(@click="close") {{ $t('promotions.cancel') }}
			.btn.btn-small.btn-peril.w-100(@click='send' :disabled='isPending') {{ $t('forms.remove') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'ModalRemoveBankCard',
	components: {
		IconClose
	},
	props: {
		item: {
			type: Object,
			default: () => (null)
		},
	},
	methods: {
		...mapActions({
			removeForAll: 'finance/removeCardFromAll'
		}),
		close () {
			this.$refs.modal.hide();
		},
		send() {
			this.removeForAll({id: this.item.id})
				.then( i => {
					this.$emit('cardDelete');
					this.$notify("error", this.$t('success.cardRemoved'));
					this.close();
				})
				.catch((v) => this.$notify("error", v))
		},
	},
	computed: {
		...mapGetters({
			isPending: 'finance/isPending',
		}),
	},
	watch: {
		item: {
			handler(nV, oV) {
				if ( nV != null ) {
					return this.$refs.modal.show();
				}

				if ( this.$refs.modal )
					this.$refs.modal.hide();
			}
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.modal-remove-bank-card {
	max-width: 360px;
	padding: 20px;
	margin: 0 auto;

	@include respond-below(sm) {
		border-bottom-left-radius: var(--border-radius-rounded) !important;
		border-bottom-right-radius: var(--border-radius-rounded) !important;
		margin: auto 12px;
	}

	&__close {
		transition: var(--animation-time-short);

		&:hover,
		&:active {
			opacity: 0.7;
		}
	}
}
</style>
