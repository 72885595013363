<template lang='pug'>
	b-modal#modalwithdrawschedule.withdraw-schedule(ref='modal' :scrollable="isMobile" :centered="!isMobile" content-class="modal-max-content" header-class="d-flex p-0 border-0 align-items-center mb-3" body-class="p-0 d-flex flex-column" hide-footer)
		template(#modal-header="{ close }")
			h3 {{ $t('finance.withdraw.modalLinkTitle') }}
			icon-close.withdraw__close(@click="close()")
		.d-flex.flex-column.withdraw-schedule__content
			p.b3.mb-3(v-html='desc')
			.d-flex.flex-column.border-rounded.withdraw-schedule__table
				span.b3.text-grey.withdraw-schedule__table-title {{ $t('finance.withdraw.schedule') }}
				.d-flex.flex-column.mt-1(v-if='payouts && payouts.length > 0')
					.d-flex.justify-content-between.border-rounded.mt-2.withdraw-schedule__table-item(v-for='p in payouts')
						span.b3.text-grey {{ p.date | dateDDMMMYYDots }}
						span.b3(v-html="p.sum")

				span.b3position-static.withdraw-schedule__table-text(v-else) {{ $t('finance.withdraw.haveNotNextPayouts') }}
			.btn.btn-primary.mt-4(@click='close') {{$t('social.understand')}}
</template>

<script>
import { mapGetters } from 'vuex';
import { SERVICE_STRING } from '@/utils/string';
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'ModalWithdrawSchedule',
	components: {
		IconClose
	},
	props: {
		card: null
	},
	methods: {
		close() {
			this.$refs.modal.hide();
		},
		onHide() {
			this.$store.commit('finance/setWithdrawSchedule', null);
		}
	},
	computed: {
		...mapGetters({
			item		: 'finance/withdrawSchedule',
		}),
		desc () {
			return this.$t('finance.withdraw.scheduleDesc').replace('%hold%', this.me.holdDuration + ' ' + SERVICE_STRING.ru_ends(this.me.holdDuration, this.$t('finance.days')));
		},
		payouts() {
			if (!this.item || !this.item.payouts) return null;
			let out = [];

			_.each( this.item.payouts, p => {
				out.push({date: p.date, sum: p.amount.priceWithCurrency()});
			})

			return out;
		}
	},
	watch: {
		item: {
			handler (nV, oV) {
				if (nV != null) {
					return this.$refs.modal.show();
				}

				if (this.$refs.modal)
					this.$refs.modal.hide();
			}
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.withdraw-schedule {
	&__content {
		width: 565px;

		@include respond-below(sm) {
			width: 100%;
		}
	}

	&__close {
		transition: var(--animation-time-short);

		&:hover,
		&:active {
			opacity: 0.7;
		}
	}

	&__table {
		width: max-content;
		padding: 20px;
		background-color: var(--brand-bg-primary);

		@include respond-below(sm) {
			width:100%;
		}
	}

	&__table-title {
		position: relative;
		padding-left: 36px;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 24px;
			height: 24px;
			background-color: var(--primary-text-color);
			mask-image: url('/assets/img/icons/calendar.svg');
			mask-repeat: no-repeat;
			mask-size: cover;
			transform: translateY(-50%);
		}
	}

	&__table-text {
		padding-left: 36px;
	}

	&__table-item {
		padding: 5px 13.5px;
		background-color: var(--foreground-color);
	}
}

#modalwithdrawschedule {
	.modal-md.modal-dialog {
		max-width: max-content;
	}

	.modal-body {
		@include respond-below(sm) {
			/* Скрываем scrollbar для IE, Edge и Firefox */
			-ms-overflow-style: none;  /* IE и Edge */
			scrollbar-width: none;  /* Firefox */

			/* Скрываем scrollbar для Chrome, Safari и Opera */
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
}
</style>
