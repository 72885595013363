<template lang='pug'>
	b-modal(
		:id="id"
		:modal-class="['modal-general', contentClass]"
		:hide-backdrop="isMobile && contentClass != 'cropper-zone' && contentClass != 'support'"
		:scrollable="isMobile"
		centered
		:content-class="['modal-general__content my-0 mx-auto']"
		header-class="d-flex justify-content-start justify-content-md-between align-items-center mb-4 border-0"
		:body-class="['d-flex flex-column', bodyClass]"
		:no-close-on-backdrop='noCloseOnBackdrop'
		hide-footer
		hide-header-close
		@hide='onHide'
	)
		template(#modal-header="{ close }")
			svg.cursor-pointer.mr-2(v-if="isMobile && contentClass != 'cropper-zone' && contentClass != 'support'" @click="close()" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg")
				path(d="M15 19.9181L8.47997 13.3981C7.70997 12.6281 7.70997 11.3681 8.47997 10.5981L15 4.07812" stroke="#8292A1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
			h3.h3(v-if="title") {{ title }}
			img.modal-general__icon(v-if="icon" :src="icon")
			icon-close.modal-general__close(v-if="!isMobile && showCloseIcon" @click="close(), $emit('click', $event)")
			icon-close.modal-general__close(v-if="isMobile && contentClass == 'cropper-zone' || isMobile && contentClass == 'support'" @click="close()")
		slot
</template>

<script>
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'ModalGeneral',
	components: {
		IconClose
	},
	props: {
		id: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		contentClass: {
			type: String,
			default: ''
		},
		bodyClass: {
			type: String,
			default: ''
		},
		showCloseIcon: {
			type: Boolean,
			default: true
		},
		noCloseOnBackdrop: {
			type: Boolean,
			default: false
		},
		icon: {
			type: String,
			default: ''
		}
	},
	methods: {
		onHide() {
			this.$emit('onHide');
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.modal-general {
	padding: 0 !important;

	@include respond-below(sm) {
		top: 68px;
	}

	/* Скрываем scrollbar для IE, Edge и Firefox */
	-ms-overflow-style: none;  /* IE и Edge */
	scrollbar-width: none;  /* Firefox */

	/* Скрываем scrollbar для Chrome, Safari и Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	&__icon {
		max-height: 27px;
	}

	.modal-dialog {
		@include respond-below(md) {
			align-self: flex-start;
			min-height: 100%;
		}

		@include respond-below(sm) {
			justify-content: flex-end !important;
		}
	}

	.modal-header {
		padding: 0 20px !important;

		@include respond-below(sm) {
			padding: 0 16px !important;
		}
	}

	.modal-body {
		padding: 0 20px !important;

		@include respond-below(sm) {
			padding: 0 16px 16px !important;

			/* Скрываем scrollbar для IE, Edge и Firefox */
			-ms-overflow-style: none;  /* IE и Edge */
			scrollbar-width: none;  /* Firefox */

			/* Скрываем scrollbar для Chrome, Safari и Opera */
			&::-webkit-scrollbar {
				display: none;
			}
		}

		&.pb-0 {
			padding-bottom: 0 !important;
		}
	}

	&__content {
		max-width: 525px;
		padding: 20px 0;

		@include respond-below(xs) {
			height: 100%;
		}

		@include respond-below(sm) {
			max-width: 100%;
			min-height: 100%;
			padding: 16px 0 64px;
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;
			box-shadow: none !important;
		}
	}

	&.content-full {
		top: 0;

		.modal-general__content {
			@include respond-below(xs) {
				min-height: 100%;
			}

			@include respond-below(sm) {
				min-height: 100%;
				padding: 16px 0;
			}
		}
	}

		&.payment-confirm {
			.modal-general__content {
				max-width: 518px;

				@include respond-below(sm-new) {
					max-width: 100%;
				}
			}

			.modal-header {
				margin-bottom: 26px !important;

				@include respond-below(sm) {
					margin-bottom: 19px !important;
				}
			}
		}

	&.content-filters {
		@include respond-below(sm) {
			height: calc(100% - 48px);
		}

		.modal-dialog {
			max-width: 686px;

			@include respond-below(sm) {
				max-width: 100%;
			}
		}

		.modal-header {
			display: none !important;
			margin: 0 !important;

			@include respond-below(sm) {
				display: flex !important;
				padding: 16px !important;
			}
		}

		.modal-body {
			padding: 0 !important;

			@include respond-below(sm) {
				padding: 0 16px 16px !important;
				overflow: visible;
			}
		}

		.modal-general__content {
			padding: 20px;
			max-width: 686px;

			@include respond-below(sm) {
				padding: 0;
				max-width: 100%;
				overflow-y: auto;
			}
		}

		&.is-followers {
			.modal-general__content {
				padding-right: 0;
			}
		}
	}

	&.cropper-zone {
		bottom: 0;

		@include respond-below(sm) {
			top: auto;
			max-height: 100%;
			height: auto;
			border-top-left-radius: var(--border-radius-rounded);
			border-top-right-radius: var(--border-radius-rounded);
		}

		.modal-general__content {
			min-width: 660px;
			max-height: 90vh;

			@include respond-below(sm) {
				padding: 16px 0;
				min-width: auto;
				height: max-content;
				border-radius: 0 !important;
			}
		}

		.modal-header {
			margin-bottom: 15px !important;
		}

		.modal-body {
			@include respond-below(sm) {
				padding: 0 16px 0 !important;
			}
		}

		.modal-general__close {
			@include respond-below(sm) {
				margin-top: -24px;
			}
		}
	}

	&.support {
		@include respond-below(sm) {
			top: auto;
			bottom: 0;
			height: auto;
			border-top-left-radius: var(--border-radius-rounded);
			border-top-right-radius: var(--border-radius-rounded);
		}

		.modal-general__content {
			@include respond-below(sm) {
				padding: 16px 0;
			}
		}
	}

	&.pixel-test-requests {
		.modal-general__content {
			max-width: 440px;

			@include respond-below(sm) {
				max-width: 100%;
			}
		}
	}

	&.max-content {
		.modal-header {
			margin-bottom: 15px !important;
		}

		.modal-general__content {
			max-width: max-content;

			@include respond-below(sm) {
				max-width: 100%;
			}
		}
	}

	&.content-covers {
		top: 0;

		.modal-header {
			align-items: flex-start !important;
			padding: 0 20px 0 64px !important;
			margin-bottom: 15px !important;

			@include respond-below(sm) {
				align-items: center !important;
				padding: 0 16px !important;
				margin-bottom: 20px !important;
			}
		}

		.modal-dialog {
			width: 100%;
			max-width: 1082px;

			.modal-general__content {
				width: 100%;
				max-width: 1082px;
				margin-left: 20px !important;
				margin-right: 20px !important;

				@include respond-below(sm) {
					padding: 16px 0 0;
					margin: 0 !important;
				}
			}

			.modal-body {
				padding: 0 64px !important;

				@include respond-below(sm) {
					padding: 0 16px 0 !important;
				}
			}
		}

		&.qr-code {
			.modal-content {
				max-width: 334px;
				padding-top: 15px;
			}

			.modal-header {
				padding-right: 13.5px !important;
				min-height: 25px;
				position: relative;
				z-index: 10;

				.icon-close {
					margin-top: -13px;
				}
			}
		}
	}
}
</style>
